<template>
  <div class="animated fadeIn">

      <!-- THIS MODAL IS TO SEE THE MASTER INVOICE INVOICES -->
      <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
        <b-card>
          <!-- <rotate-square2 id="loading-gif" v-show="loadingInvoiceData"></rotate-square2> -->
          <div class="row">
            <div class="col-md-12">
              <Master-Invoice v-bind:propafes="this.afes" v-bind:propinvoice="this.invoice"> </Master-Invoice>
            </div>
          </div>
        </b-card>
      </b-modal>

    <b-card>
      <InvoiceHeader v-bind:invoice="invoice" v-bind:manager="1" v-bind:highestAfe="highestAfe" v-bind:serviceData="serviceData" v-bind:afes="afes" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name"></InvoiceHeader>

      <div class="mt-2 invoice-status">
        <div v-if="invoice.accountant_paid === 0">
          <div v-if="invoice.inv_passed === 9">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
          </div>

          <div v-if="invoice.inv_passed === 8 ">
            <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
          </div>

          <div v-if="invoice.inv_passed === 7 ">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 6 ">
            <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By Management</button>
          </div>

          <div v-if="invoice.inv_passed === 5 ">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 0 ">
            <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By WSS</button>
          </div>

          <div v-if="invoice.inv_passed === 2 ">
            <button class="btn-sm btn-blue"><i class="fa fa-save"></i> Draft</button>
          </div>

          <div v-if="invoice.inv_passed === 11">
           <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 13">
           <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By <span v-if="authority <= 1">Higher </span>Management</button>
         </div>

          <div v-if="invoice.inv_passed === 14">
           <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 21">
           <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 23">
           <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By <span v-if="authority <= 2">Higher </span>Management</button>
          </div>

          <div v-if="invoice.inv_passed === 24">
           <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 31">
           <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 33">
           <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By <span v-if="authority <= 3">Higher </span>Management</button>
          </div>

          <div v-if="invoice.inv_passed === 34">
           <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 41">
           <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 43">
           <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By <span v-if="authority <= 4">Higher </span>Management</button>
          </div>

          <div v-if="invoice.inv_passed === 44">
           <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 51">
           <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>

          <div v-if="invoice.inv_passed === 54">
           <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
          </div>
        </div>
        <div v-if="invoice.accountant_paid === 1">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
        </div>
      </div>
      <div>
         <b-button v-if="!fromexternal" v-b-modal.master-invoice-modal  class="pull-right pl-3 btn-sm font-weight-bold" variant="primary"><span class="mt-2 d-none d-sm-inline mr-1">View Master Invoice </span><i class="fas fa-search"></i></b-button>
      </div>
      <b-row class="mt-3 clearfix mb-4">
        <b-col md="6" lg="4">
          <VendorDetails v-bind:invoice="invoice" v-bind:manager="manager" v-bind:vid="invoice.vid"></VendorDetails>
        </b-col>
        <b-col md="6" lg="4">
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="company_name"></CompanyDetails>
        </b-col>
        <b-col md="6" lg="4">
          <InvoiceDetails v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:vwref="vwref"></InvoiceDetails>
        </b-col>
      </b-row>

      <InvoiceAfes v-bind:afes="afes" v-bind:invoice="invoice" v-bind:allowSubmit="true"></InvoiceAfes>
      <b-row>
        <b-col lg="12" class="mt-4 col-12 ml-auto">
          <InvoiceTotals v-bind:invoice="invoice" v-bind:serviceData="serviceData" v-bind:currency="currency"></InvoiceTotals>
        </b-col>
        <b-col lg="12" class="mt-4 col-12 ml-auto">
          <div v-if="set === 1">
            <h5>Comments <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Comments will be seen by all user levels and be attached on invoice"></i></h5>
              <textarea name="comments" v-model="comments" id="" class="w-100 rounded border mt-2 mb-4" rows="3"></textarea>
              <button class="btn btn-danger mr-4 mb-4" @click="submitInvoice(2)">Reject <i class="fa fa-times"></i></button>
              <b-dropdown class="mr-4 mb-4" variant="primary" text="Send Back To">
                <b-dropdown-item v-on:click="kickBack(8)">Project Manager</b-dropdown-item>
                <b-dropdown-item v-on:click="kickBack(0)">Well Site Supervisor</b-dropdown-item>
              </b-dropdown>
              <button type="submit" @click="submitInvoice(1)" class="btn btn-success mb-4"><i class="fa fa-check"></i> Approve</button>
              <div v-show="invoiceError" class="d-block">
                <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError" class="help">Invoice Error. Please fix invoice or contact admin.</span>
              </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <InvoiceHistory v-bind:history="history"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import VendorDetails from './../invoice/InvoiceVendorDetails'
import CompanyDetails from './../invoice/InvoiceCompanyDetails'
import InvoiceDetails from './../invoice/InvoiceDetails'
import InvoiceHeader from './../invoice/InvoiceHeader'
import InvoiceServices from './../invoice/InvoiceServices'
import InvoiceAfes from './../invoice/InvoiceAfes'
import InvoiceTotals from './../invoice/InvoiceTotals'
import InvoiceHistory from './../invoice/InvoiceHistory'
import MasterInvoice from './../../components/MasterInvoice'
import moment from 'moment';

export default {
  name: 'ManagerInvoice2',
  components: {
    InvoiceHeader,
    VendorDetails,
    InvoiceServices,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
    'Master-Invoice' : MasterInvoice
  },
  props : ['dontRedirect','fromexternal'],
  data () {
    return {
      today: new Date(),
      uom: [
        { value: 'L', label: 'L' },
        { value: 'KG', label: 'KG' },
      ],
      vendorDetails: [],
      serviceNames: [
        { value: 'Drilling', label: 'Drilling', code: '9294'},
        { value: 'Water', label: 'Water', code: '9594'},
      ],
      allafe: [
        { value: '34353', label: '34353'},
        { value: '65434', label: '65434'},
      ],
      service: {
        startdate: '',
        enddate: '',
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      afes: [],
      percent: 0,
      passLimit: 0,
      vwref: [],
      history: [],
      afeData: [],
      gst: 5,
      quickpay: 0,
      services: [],
      serviceData: [],
      comments: "",
      invoiceError: false,
      tax_percentage: 0,
      deleteAps: [],
      serviceChainData: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      company_name: '',
      cc1: [],
      cc2: [],
      cc3: [],
      highestAfe: [],
      manager: true,
      currency: '',
      invoice: [],
      authority: 0,
      set: 0,
      usersName: ""
    }
  },
  methods: {
    kickBack(pass){
      let data = JSON.stringify({
        "invnum": this.invoice.invnum,
        "inv_pass": pass
      })
      this.$http.post('/wts/edit/invpass_kickback', data)
      .then(response => {
        this.$toasted.show('Invoice Sent Back Successfully', {type: 'success', duration: '3000'});
        this.$router.push('/manager')
      })
      .catch(error => {
      })
    },
    submitInvoice(status) {
      var comments = this.usersName + " ($" + this.formatPrice(this.invoice.total) + ")";
      if(this.comments != ""){
        comments += ": " + this.comments
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        manager_stat: status,
        manager_note: comments,
      })
      this.$http.post('/manager/edit/invoice', data)
      .then(response => {
        if (response.status != 200) {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
        this.error = true;
      }
      if (response.status === 200) {
          this.$toasted.show('Invoice Updated Successfully', {type: 'success', duration: '3000'});
          if(this.dontRedirect){
            this.$emit('onStatusUpdate',  this.invoice.invoiceid);
          } else {
            this.$router.push('/manager/')
          }
        }
      })
      .catch(error => {
        this.invoiceError = true;
      })
    },
    loadData() {
      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;

      this.$http.get('/manager/get/invoice_services/' + pid + '/' + invid)
      .then(response => {
        this.serviceData = this.lodash.uniqBy(response.data.result, 'vsid');
        this.currency = this.serviceData[0].currency;

        const that = this;
        this.afes = response.data.result.map(function (afe) {
          afe.index = that.serviceData.findIndex(x=>x.vsid === afe.vsid)
          return afe
        });

        if(this.afes){
          var res =   Math.max.apply(Math,response.data.result.map(function(o){return o.percentage;}))
          this.highestAfe = response.data.result.find(function(o){ return o.percentage == res; })
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/manager/get/profile/')
      .then(response => {
        this.company_name = response.data.company;
        this.passLimit = response.data.passlimit;
        this.authority = response.data.authority;
        this.manager = true;
        this.usersName = response.data.fname + " " + response.data.lname;
      })
      .catch(error => {

      })
    }
  },
  computed: {
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    tax: function(){
      return this.formatPrice(this.subtotal * (this.invoice.tax_percentage/100 ))
    },
  },
  mounted(){
    if(!this.$route.params.invoice){
      this.invoice = this.$session.get('invoice');
      this.set = this.$session.get('set');
    }
    else {
      this.invoice = this.$route.params.invoice;
      this.set = this.$route.params.set;
    }

    this.quickpay = this.invoice.discount;
    this.gst = this.invoice.tax_percentage;
    this.history = this.invoice;
    var companyid = this.invoice.companyid;
    var a = moment(this.invoice.endtday);
    var b = moment(this.invoice.creation_date);
    this.netDays = a.diff(b, 'days')

    this.loadData();
  }
}

</script>
