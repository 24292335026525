<template>
  <div class="animated fadeIn">

    <!-- THIS MODAL IS TO SEE THE MASTER INVOICE INVOICES -->
    <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
      <b-card>
        <!-- <rotate-square2 id="loading-gif" v-show="loadingInvoiceData"></rotate-square2> -->
        <div class="row">
          <div class="col-md-12">
            <Master-Invoice v-bind:propafes="this.afes" v-bind:propinvoice="this.invoice"> </Master-Invoice>
          </div>
        </div>
      </b-card>
    </b-modal>

    <b-card>
      <InvoiceHeader v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:serviceData="serviceData" v-bind:afes="afes" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name"></InvoiceHeader>

      <div class="mt-2 invoice-status">
        <div v-if="invoice.accountant_paid === 0">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>
        <div v-if="invoice.accountant_paid === 1">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
        </div>
      </div>

      <div>
        <b-button v-b-modal.master-invoice-modal class="pull-right pl-3 btn-sm font-weight-bold" variant="primary"><span class="mt-2 d-none d-sm-inline mr-1">View Master Invoice </span><i class="fas fa-search"></i></b-button>
      </div>

      <b-row class="mt-3 clearfix mb-4">
        <b-col md="6" lg="4">
          <VendorDetails v-bind:invoice="invoice"></VendorDetails>
        </b-col>
        <b-col md="6" lg="4">
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="company_name"></CompanyDetails>
        </b-col>
        <b-col md="6" lg="4">
          <InvoiceDetails v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:vwref="vwref"></InvoiceDetails>
        </b-col>
      </b-row>

      <InvoiceAfes v-bind:afes="afes" v-bind:serviceData="serviceData" v-bind:invoice="invoice" v-bind:allowSubmit="true"></InvoiceAfes>

      <b-row>
        <b-col lg="12" class="mt-4 col-12 ml-auto">
          <InvoiceTotals v-bind:invoice="invoice" v-bind:serviceData="serviceData" v-bind:currency="currency"></InvoiceTotals>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <InvoiceHistory v-bind:history="history"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import VendorDetails from './../invoice/InvoiceVendorDetails'
import CompanyDetails from './../invoice/InvoiceCompanyDetails'
import InvoiceDetails from './../invoice/InvoiceDetails'
import InvoiceHeader from './../invoice/InvoiceHeader'
import InvoiceServices from './../invoice/InvoiceServices'
import InvoiceAfes from './../invoice/InvoiceAfes'
import InvoiceTotals from './../invoice/InvoiceTotals'
import InvoiceHistory from './../invoice/InvoiceHistory'
import MasterInvoice from './../../components/MasterInvoice'
import moment from 'moment';

export default {
  name: 'AccountantInvoice2',
  components: {
    vSelect,
    Datepicker,
    InvoiceHeader,
    VendorDetails,
    InvoiceServices,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
    'Master-Invoice' : MasterInvoice
  },
  data () {
    return {
      chequeDate: "",
      chequeNumber: "",
      invoice: [],
      today: new Date(),
      uom: [
        { value: 'L', label: 'L' },
        { value: 'KG', label: 'KG' },
      ],
      vendorDetails: [],
      serviceNames: [
        { value: 'Drilling', label: 'Drilling', code: '9294'},
        { value: 'Water', label: 'Water', code: '9594'},
      ],
      allafe: [
        { value: '34353', label: '34353'},
        { value: '65434', label: '65434'},
      ],
      service: {
        startdate: '',
        enddate: '',
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      afes: [],
      percent: 0,
      vwref: [],
      afeData: [],
      gst: 5,
      quickpay: 0,
      services: [],
      serviceData: [],
      comments: "",
      invoiceError: false,
      tax_percentage: 0,
      deleteAps: [],
      serviceChainData: [],
      uwiData: [],
      history: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      company_name: '',
      cc1: [],
      cc2: [],
      cc3: [],
      highestAfe: [],
      netDays: 0,
      currency: '',
      usersName: '',
    }
  },
  methods: {
    submitInvoice(status) {
      var comments = this.usersName;
      if(this.comments != ""){
        comments += ": " + this.comments;
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        accountant_paid: status,
        ac_note: comments,
        check_round_date: this.chequeDate,
        check_number: this.chequeNumber
      })
      this.$http.post('/accountant/edit/invoice', data)
      .then(response => {
        this.$toasted.show('Invoice Updated Successfully', {type: 'success', duration: '3000'});
        this.$router.push('/accountant')
      })
      .catch(error => {
        this.invoiceError = true;
      })
    },
    loadData() {
      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;

      this.$http.get('/manager/get/invoice_services/' + pid + '/' + invid)
      .then(response => {
        this.serviceData = this.lodash.uniqBy(response.data.result, 'vsid');
        this.currency = this.serviceData[0].currency;

        const that = this;
        this.afes = response.data.result.map(function (afe) {
          afe.index = that.serviceData.findIndex(x=>x.vsid == afe.vsid)
          return afe
        });

        if(this.afes){
          var res = Math.max.apply(Math,response.data.result.map(function(o){return o.percentage;}))
          this.highestAfe = response.data.result.find(function(o){ return o.percentage == res; })
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/manager/get/profile/')
      .then(response => {
        this.company_name = response.data.company;
      })
      .catch(error => {

      })
    }
  },
  computed: {
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    tax: function(){
      return this.formatPrice(this.subtotal * (this.invoice.tax_percentage/100 ))
    },
  },
  mounted(){
    if(!this.$route.params.invoice){
      this.invoice = this.$session.get('invoice');
    }
    else {
      this.invoice = this.$route.params.invoice;
    }

    this.$http.get('manager/get/profile').then(response => {
      this.usersName = response.data.fname + " " + response.data.lname;
    })

    this.invoiceID = this.invoice.invoiceid;
    this.quickpay = this.invoice.discount;
    this.gst = this.invoice.tax_percentage;
    this.history = this.invoice;
    var companyid = this.invoice.companyid;
    var a = moment(this.invoice.endtday);
    var b = moment(this.invoice.creation_date);
    this.netDays = a.diff(b, 'days')

    this.loadData();
  }
}

</script>
