var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: { propafes: this.afes, propinvoice: this.invoice }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              highestAfe: _vm.highestAfe,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c("div", { staticClass: "mt-2 invoice-status" }, [
            _vm.invoice.accountant_paid === 0
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Ready For Payment")
                  ])
                ])
              : _vm._e(),
            _vm.invoice.accountant_paid === 1
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Paid")
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.master-invoice-modal",
                      modifiers: { "master-invoice-modal": true }
                    }
                  ],
                  staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                  attrs: { variant: "primary" }
                },
                [
                  _c("span", { staticClass: "mt-2 d-none d-sm-inline mr-1" }, [
                    _vm._v("View Master Invoice ")
                  ]),
                  _c("i", { staticClass: "fas fa-search" })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [_c("VendorDetails", { attrs: { invoice: _vm.invoice } })],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("InvoiceDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      highestAfe: _vm.highestAfe,
                      vwref: _vm.vwref
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("InvoiceAfes", {
            attrs: {
              afes: _vm.afes,
              serviceData: _vm.serviceData,
              invoice: _vm.invoice,
              allowSubmit: true
            }
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _c("InvoiceTotals", {
                    attrs: {
                      invoice: _vm.invoice,
                      serviceData: _vm.serviceData,
                      currency: _vm.currency
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [_c("InvoiceHistory", { attrs: { history: _vm.history } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }