var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: { propafes: this.afes, propinvoice: this.invoice }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              manager: 1,
              highestAfe: _vm.highestAfe,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c("div", { staticClass: "mt-2 invoice-status" }, [
            _vm.invoice.accountant_paid === 0
              ? _c("div", [
                  _vm.invoice.inv_passed === 9
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By WSS (Submit Again)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 8
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-pm" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Project Manager")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 7
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 6
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-warning" }, [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Pending By Management")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 5
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Project Manager (Pending Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 0
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-warning" }, [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Pending By WSS")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 2
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-blue" }, [
                          _c("i", { staticClass: "fa fa-save" }),
                          _vm._v(" Draft")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 11
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 13
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 1
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 14
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 21
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 23
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 2
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 24
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 31
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 33
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 3
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 34
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 41
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 43
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 4
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 44
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 51
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 54
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.invoice.accountant_paid === 1
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Paid")
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            [
              !_vm.fromexternal
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.master-invoice-modal",
                          modifiers: { "master-invoice-modal": true }
                        }
                      ],
                      staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                      attrs: { variant: "primary" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mt-2 d-none d-sm-inline mr-1" },
                        [_vm._v("View Master Invoice ")]
                      ),
                      _c("i", { staticClass: "fas fa-search" })
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("VendorDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      manager: _vm.manager,
                      vid: _vm.invoice.vid
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("InvoiceDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      highestAfe: _vm.highestAfe,
                      vwref: _vm.vwref
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("InvoiceAfes", {
            attrs: { afes: _vm.afes, invoice: _vm.invoice, allowSubmit: true }
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _c("InvoiceTotals", {
                    attrs: {
                      invoice: _vm.invoice,
                      serviceData: _vm.serviceData,
                      currency: _vm.currency
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _vm.set === 1
                    ? _c(
                        "div",
                        [
                          _c("h5", [
                            _vm._v("Comments "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "ml-2 fa fa fa-question-circle",
                              attrs: {
                                title:
                                  "Comments will be seen by all user levels and be attached on invoice"
                              }
                            })
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.comments,
                                expression: "comments"
                              }
                            ],
                            staticClass: "w-100 rounded border mt-2 mb-4",
                            attrs: { name: "comments", id: "", rows: "3" },
                            domProps: { value: _vm.comments },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.comments = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mr-4 mb-4",
                              on: {
                                click: function($event) {
                                  return _vm.submitInvoice(2)
                                }
                              }
                            },
                            [
                              _vm._v("Reject "),
                              _c("i", { staticClass: "fa fa-times" })
                            ]
                          ),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-4 mb-4",
                              attrs: {
                                variant: "primary",
                                text: "Send Back To"
                              }
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.kickBack(8)
                                    }
                                  }
                                },
                                [_vm._v("Project Manager")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.kickBack(0)
                                    }
                                  }
                                },
                                [_vm._v("Well Site Supervisor")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success mb-4",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  return _vm.submitInvoice(1)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-check" }),
                              _vm._v(" Approve")
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.invoiceError,
                                  expression: "invoiceError"
                                }
                              ],
                              staticClass: "d-block"
                            },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.invoiceError,
                                    expression: "invoiceError"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.invoiceError,
                                      expression: "invoiceError"
                                    }
                                  ],
                                  staticClass: "help"
                                },
                                [
                                  _vm._v(
                                    "Invoice Error. Please fix invoice or contact admin."
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [_c("InvoiceHistory", { attrs: { history: _vm.history } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }